













import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    props: {
        loadoverText: String,
        loadingText: String,
        loading: Boolean,
        loadover: Boolean
    },
    setup() {}
})
